import { Injectable } from '@angular/core';
import { getNumberValuesFromDollarAndPercent } from 'src/app/utils/utils';

export const VISITS_IDS_AND_PRICES = {
	pcp: {
		id: '5d9c3af263507c70ef68860f',
		price: 175,
	},
	specialist: {
		id: '5d9c3af763507c70ef688642',
		price: 300,
	},
	pregnancy: {
		id: '631bf5bbfc9817f7b7dd181d',
		price: 14573,
	},
	er_visit: {
		id: '5d9c3af263507c70ef68861b',
		price: 1055,
	},
	outpatient_procedure: {
		id: '626a0589c2a6ca4d48707f84',
		id2: '6267426d6ee5c4ac2c68c26e',
		price: 2143,
	},
	inpatient_procedure: {
		id: '617907e886563262b0c24b53',
		price: 14365,
	},
	eye_exam: {
		id: '61ef1344d0a2ab18c9b07cbb',
	},
	frames: {
		id: '61e970d790f66c4ccfcec2e6',
	},
	single_vision_lenses: {
		id: '61e970e690f66c4ccfcec2e7',
	},
	conventional_contact_lenses: {
		id: '61e9749d90f66c4ccfcec2ef',
	},
};

@Injectable({
	providedIn: 'root',
})
export class ComparisonTableHelper {
	public calcOopAmount(
		plan,
		numOfVisitsPcp,
		numOfVisitsSpecialists,
		hsaCont
	): { oopAmount: number; remainingDeductible: number } {
		let remainingDeductible = plan.yearlyDeductible || 0;
		console.log(plan);
		console.log(remainingDeductible);
		console.log({ numOfVisitsPcp });
		console.log({ numOfVisitsSpecialists });
		const pcpService = plan.serviceData.find((item) => item.id === VISITS_IDS_AND_PRICES.pcp.id);
		const specService = plan.serviceData.find((item) => item.id === VISITS_IDS_AND_PRICES.specialist.id);
		console.log({ pcpService });
		console.log({ specService });
		// there's a typo in the plan data, so we need to check both properties
		const pcpSubjectToDeductible = pcpService?.subjectToDeductableIn || pcpService?.subjectToDeductibleIn;
		const specialistSubjectToDeductible = specService?.subjectToDeductableIn || specService?.subjectToDeductibleIn;

		// Define a helper function to calculate OOP considering the deductible.
		const calculateOopForService = (numOfVisits, serviceType, subjectToDeductible) => {
			console.log('calculateOopForService', { numOfVisits, serviceType, subjectToDeductible });
			let totalCostAfterDiscounts = 0;
			for (let i = 0; i < numOfVisits; i++) {
				const serviceCostBeforeDiscount = VISITS_IDS_AND_PRICES[serviceType].price;
				const serviceCostAfterDiscount = this.calcServiceOOP(plan, VISITS_IDS_AND_PRICES[serviceType]);

				if (subjectToDeductible && remainingDeductible > 0) {
					// Apply the deductible
					const deductibleApplicable = Math.min(serviceCostBeforeDiscount, remainingDeductible);
					remainingDeductible -= deductibleApplicable;

					// After deductible is applied, calculate remaining amount subject to copay or coinsurance
					const costAfterDeductible = serviceCostBeforeDiscount - deductibleApplicable;
					totalCostAfterDiscounts += Math.min(serviceCostAfterDiscount, costAfterDeductible);
				} else {
					// If not subject to deductible, use the discounted cost directly
					totalCostAfterDiscounts += serviceCostAfterDiscount;
				}
			}
			return totalCostAfterDiscounts;
		};

		// Calculate OOP for PCP and specialist visits.
		const pcpOop = calculateOopForService(numOfVisitsPcp, 'pcp', pcpSubjectToDeductible);
		const specialistOop = calculateOopForService(
			numOfVisitsSpecialists,
			'specialist',
			specialistSubjectToDeductible
		);

		console.log({ pcpOop });
		console.log({ specialistOop });
		const deductiblePaid = plan.yearlyDeductible - remainingDeductible;
		console.log({ deductiblePaid });

		// Sum of OOP costs.
		let oopAmount = pcpOop + specialistOop + deductiblePaid;

		// Apply the out-of-pocket maximum limit.
		if (oopAmount > plan.outOfPocketMax) {
			oopAmount = plan.outOfPocketMax;
		}

		// Apply HSA contributions.
		oopAmount = Math.max(0, oopAmount - hsaCont);

		// Return both OOP amount and remaining deductible.
		return {
			oopAmount: oopAmount,
			remainingDeductible: remainingDeductible,
		};
	}

	public calcServiceOOP(plan, visitObj) {
		const findService = plan.serviceData.find((item) => item.id === visitObj.id || item.id === visitObj.id2);
		if (!findService) return;
		const copayText = findService.inNetwork;
		let copay = 0;
		let percentage = 0;

		if (!copayText.includes('%')) return this.parseNumber(copayText);
		if (copayText.includes('$')) {
			const numberValues = getNumberValuesFromDollarAndPercent(copayText);
			copay = numberValues[0];
			percentage = numberValues[1];
		} else {
			percentage = this.parseNumber(copayText);
		}
		return copay + ((visitObj.price - copay) * percentage) / 100;
	}

	public parseNumber(string: string): number | undefined {
		if (!string) return undefined;
		const parsedNumber = parseFloat(string.replace(/[^.0-9]/g, ''));
		if (isNaN(parsedNumber)) return undefined;

		return parsedNumber;
	}

	public normalizeServiceValue(status: string): string {
		const normalizedStatus = status?.replace(' after deductible', '');

		if (normalizedStatus === '0%') {
			return '100% covered';
		}

		return normalizedStatus;
	}

	public calcPremiumPerPayPeriod(plan, activeFilter): number {
		if (plan.premiums[activeFilter] === undefined) return plan.premiums['family'];
		return plan.premiums[activeFilter];
	}

	public calcAccordingToFilter(obj, activeFilter, networkOption): any {
		if (!obj) return undefined;
		if (activeFilter === 'individual') return obj.individual?.[networkOption];
		// if (activeFilter === 'individualPlusSpouse')
		// 	return obj.individualPlusOne?.[networkOption] || obj.family?.[networkOption];
		// if (activeFilter === 'individualPlusOne')
		// 	return obj.individualPlusOne?.[networkOption] || obj.family?.[networkOption];
		if (activeFilter === 'family') return obj.family?.[networkOption];
	}

	public calcDeductibleAccordingToFilter(obj, activeFilter, networkOption): any {
		if (!obj) return undefined;
		if (activeFilter === 'individual') return obj.individual?.[networkOption]?.text;
		// if (activeFilter === 'individualPlusSpouse')
		// 	return obj.individualPlusOne?.[networkOption]?.text || obj.family?.[networkOption]?.text;
		// if (activeFilter === 'individualPlusOne')
		// 	return obj.individualPlusOne?.[networkOption]?.text || obj.family?.[networkOption]?.text;
		if (activeFilter === 'family') return obj.family?.[networkOption]?.text;
	}

	public calcYearlyDeductible(plan, activeFilter, networkOption): number {
		const deductible = this.calcDeductibleAccordingToFilter(plan.contract.deductible, activeFilter, networkOption);
		return this.parseNumber(deductible);
	}

	public dentalPlanDetail(obj, networkOption): number {
		const value = obj?.individual?.[networkOption];
		if (!value) return undefined;

		return this.parseNumber(value);
	}

	public calcCalenderYearMax(plan, networkOption): number {
		return this.dentalPlanDetail(plan.contract.calenderMax, networkOption);
	}

	public calcCalenderYearMaxIndividual(plan, networkOption): number {
		const value = this.calcAccordingToFilter(plan.contract.calenderMax, 'individual', networkOption);
		return this.parseNumber(value);
	}

	public calcOrthodonticsLifetimeMax(plan, networkOption): number {
		return this.dentalPlanDetail(plan.contract.orthodnoticsMax, networkOption);
	}

	public calcOrthodonticsLifetimeMaxIndividual(plan, networkOption): number {
		const value = this.calcAccordingToFilter(plan.contract.orthodnoticsMax, 'individual', networkOption);
		return this.parseNumber(value);
	}

	public calcOutOfPocketMax(plan, activeFilter, networkOption): number {
		const value = this.calcAccordingToFilter(plan.contract.outOfPocketMax, activeFilter, networkOption);
		return this.parseNumber(value);
	}

	public calcHsaAmount(plan, activeFilter) {
		if (activeFilter === 'individual') {
			return plan.hsaAnnualContribution;
		}

		// if (activeFilter === 'individualPlusSpouse') {
		// 	return plan.hsaAnnualContributionPlusSpouse || plan.hsaAnnualContribution;
		// }
		//
		// if (activeFilter === 'individualPlusOne') {
		// 	return plan.hsaAnnualContributionPlusOne || plan.hsaAnnualContribution;
		// }

		if (activeFilter === 'family') {
			return plan.hsaAnnualContributionFamily || plan.hsaAnnualContribution;
		}
	}
}
