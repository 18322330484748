import { ServiceTypeEnum } from 'src/app/models/chat-response.model';

export const enum MpiPlan {
	Anthem = 'Anthem',
	Kaiser = 'Kaiser',
	Oxford = 'Oxford',
	HealthNet = 'Health Net',
	CVS = 'CVS Caremark - Pharmacy',
}

export enum MpiPlanId {
	Anthem = '656f43a0a6f244f2ee8dfb11',
	Kaiser = '65773f2230bba239b851d0ea',
	Oxford = '64ad77eb2475a65f7a26df35',
	HealthNet = '646cdd4d1625ae7343e1850f',
	CVS = '65b40e13e8197b8f6f7c1e65',
}

const planToServiceTypeMap: { [key in MpiPlanId]: ServiceTypeEnum } = {
	[MpiPlanId.Anthem]: ServiceTypeEnum.Medical,
	[MpiPlanId.Kaiser]: ServiceTypeEnum.Medical,
	[MpiPlanId.Oxford]: ServiceTypeEnum.Medical,
	[MpiPlanId.HealthNet]: ServiceTypeEnum.Medical,
	[MpiPlanId.CVS]: ServiceTypeEnum.Pharmacy,
};

//Make sure number of services stays 4
export const AnthemPopularBenefitIds = [
	'5d9c3af263507c70ef68860f', // Primary Care
	'5dd95d2763d42e623008f791', // Pediatrician
	'6647acc960987a9d74bedc14', // Annual physical Exam a.k.a Wellness Visit
	'5ddfaa5fcace7e30c3c90d6e', // Telehealth
];

export const ServiceNamesForPopularBenefits = {
	'5d9c3af263507c70ef68860f': 'Primary Care',
	'5dd95d2763d42e623008f791': 'Pediatrics',
	'6647acc960987a9d74bedc14': 'Wellness Visit',
	'5ddfaa5fcace7e30c3c90d6e': 'Telehealth',
};

// Other MPI utils

export const getServiceTypeForPlan = (planId: MpiPlanId): ServiceTypeEnum =>
	planToServiceTypeMap[planId] || ServiceTypeEnum.Medical;
